import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import Header from "../Header/Header";
import CampList from "../Blocks/CampList/CampList";
import CampTermsBlock from "../Blocks/CampTermsBlock/CampTermsBlock";
import ChangeBlock from "../Blocks/ChangeBlock/ChangeBlock";
import DayTime from "../Blocks/DayTime/DayTime";
import CampLocation from "../Blocks/CampLocation/CampLocation";
import TeamBlock from "../Blocks/TeamBlock/TeamBlock";
import Footer from "../Footer/Footer";

import './ChangePage.css';

// import IconLife from "../CampChange/Life/icon-life.png";
// import IconFood from "../CampChange/Food/icon-food.png";
// import IconFive from "../CampChange/Five/icon-five.png";
// import IconGame from "../CampChange/Game/icon-game.png";
// import IconGalaxy from "../CampChange/Galaxy/icon-galaxy.png";

import IconLife from "../../img/changeCamp/icons/01-change.png";
import IconFood from "../../img/changeCamp/icons/02-change.png";
import IconFive from "../../img/changeCamp/icons/03-change.png";
import IconGame from "../../img/changeCamp/icons/04-change.png";
import IconGalaxy from "../../img/changeCamp/icons/05-change.png";

class ChangePage extends React.Component {
    render() {
        return (
            <div>
                <Header />
                <div>
                    <div className="page_change">
                        <div className="title title_p p-t120">
                            <p><span className="page-title white_color">Змiни</span></p>
                        </div>
                        <Container>
                            <Row className="row">
                                <Col md="12">
                                    <div className="text_in_block bg_about white-color">
                                        <p className="">
                                            Зміна у таборі триває 12 днів, тематика завжди різна та ніколи не повторюється.
                                            Тема зміни розкривається через вечірні заходи, ігри,
                                            випробування та творчі майстерні.

                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        <Container className="m-b20">
                            <Row >
                                <Col xs="12" md="4">
                                    <div className="change_page_text bg_lifeText">
                                        <p className="tittle"><img src={IconLife} alt="зміна Life" style={{
                                            width: '70px',
                                            heigh: '70px'
                                        }} /> 1 ЗМІНА «Шалені канікули»</p>
                                        <p className="t-center">15.06-26.06.2022</p>
                                        <div>
                                            <div className="price green-bg m-r-5">18 000 ₴</div>
                                            <a href="/life">
                                                <div className="price light_btn_1change">Детальніше</div>
                                            </a>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="12" md="4">
                                    <div className="change_page_text bg_foodText">
                                        <p className="tittle"><img src={IconFood} alt="зміна Food" style={{
                                            width: '70px',
                                            heigh: '70px'
                                        }} /> 2 ЗМІНА «По той бік екрану»</p>
                                        <p className="t-center">30.06-11.07.2022</p>
                                        <div>
                                            <div className="price green-bg m-r-5">18 000 ₴</div>
                                            <a href="/food">
                                                <div className="price light_btn_2change">Детальніше</div>
                                            </a>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="12" md="4">
                                    <div className="change_page_text bg_fiveText">
                                        <p className="tittle"><img src={IconFive} alt="зміна Five" style={{
                                            width: '70px',
                                            heigh: '70px'
                                        }} /> 3 ЗМІНА «Діссендіум»</p>
                                        <p className="t-center">15.07-26.07.2022</p>
                                        <div>
                                            <div className="price green-bg m-r-5">18 000 ₴</div>
                                            <a href="/five">
                                                <div className="price light_btn_3change">Детальніше</div>
                                            </a>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="12" md={{ span: 4, offset: 2 }}>
                                    <div className="change_page_text bg_gameText">
                                        <p className="tittle"><img src={IconGame} alt="зміна Game" style={{
                                            width: '70px',
                                            heigh: '70px'
                                        }} /> 4 ЗМІНА «Тотальне перезавантаження»</p>
                                        <p className="t-center">31.07-11.08.2022</p>
                                        <div>
                                            <div className="price green-bg m-r-5">18 000 ₴</div>
                                            <a href="/game">
                                                <div className="price light_btn_4change">Детальніше</div>
                                            </a>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="12" md="4">
                                    <div className="change_page_text bg_galaxyText">
                                        <p className="tittle"><img src={IconGalaxy} alt="зміна Galaxy" style={{
                                            width: '70px',
                                            heigh: '70px'
                                        }} /> 5 ЗМІНА «За 280 годин»</p>
                                        <p className="t-center">16.08-27.08.2022</p>
                                        <div>
                                            <div className="price green-bg m-r-5">18 000 ₴</div>
                                            <a href="/galaxy">
                                                <div className="price light_btn_5change">Детальніше</div>
                                            </a>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <CampList />
                    <CampTermsBlock />
                    <DayTime />
                    <TeamBlock />
                    <ChangeBlock />
                    <CampLocation />
                </div >
                <Footer />
            </div >
        );
    }
}

export default ChangePage;
