import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Header from "../../Header/Header";
import CampList from "../../Blocks/CampList/CampList";
import CampTermsBlock from "../../Blocks/CampTermsBlock/CampTermsBlock";
import DayTime from "../../Blocks/DayTime/DayTime";
import ChangeBlock from "../../Blocks/ChangeBlock/ChangeBlock";
import TeamBlock from "../../Blocks/TeamBlock/TeamBlock";
import CampLocation from "../../Blocks/CampLocation/CampLocation";
import Footer from "../../Footer/Footer";


import './Galaxy.css';
import Icon from "../../../img/changeCamp/icons/05-change.png";

class Galaxy extends React.Component {
    render() {
        return (
            <div>
                <Header />
                <div>
                    <div className="galaxy_page">
                        <div className="chang_block">
                            <Container >
                                <Row>
                                    <Col xs="12" sm="8" lg="6" md={{ offset: 6 }}>
                                        <div className="change_text bg_galaxyText">
                                            <i></i>
                                            <p className="tittle"><img src={Icon} alt="зміна Galaxy" /> 5 ЗМІНА</p>
                                            <p className="tittle">«За 280 годин»</p>
                                            <p>16.08-27.08.2022</p>
                                            {/* <ul>
                                                <li><span>Розглянемо зірки та планети</span></li>
                                                <li><span>Дізнаємося як зберегти планету здоровою</span></li>
                                                <li><span>Зустрінемося з еко-обізнаними людьми та інопланетними жителями</span></li>
                                                <li><span>Проведемо експерименти</span></li>
                                                <li><span>Дізнаємося як світили зірки під час нашої зустрічі</span></li>
                                            </ul> */}
                                            <div className="price">18 000 ₴</div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        <div className="change_text bg_galaxyText">
                                            <div className="t-indent">Щоб дізнатися ціну однієї тисячної секунди, запитай у срібного медаліста Олімпійських
                                                ігор…</div>
                                            <div className="t-indent">За 288 годин зміни треба встигнути зробити все, щоб запам’ятати це літо та сміливо
                                                розпочати осінь.</div>
                                            <div className="t-indent">Ми підкоримо час та навчимося керувати ним.</div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                    <CampList />
                    <CampTermsBlock />
                    <DayTime />
                    <ChangeBlock />
                    <TeamBlock />
                    <CampLocation />
                </div>
                <Footer />
            </div>
        );
    }
}

export default Galaxy;
