import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Header from "../../Header/Header";
import CampList from "../../Blocks/CampList/CampList";
import CampTermsBlock from "../../Blocks/CampTermsBlock/CampTermsBlock";
import DayTime from "../../Blocks/DayTime/DayTime";
import ChangeBlock from "../../Blocks/ChangeBlock/ChangeBlock";
import TeamBlock from "../../Blocks/TeamBlock/TeamBlock";
import CampLocation from "../../Blocks/CampLocation/CampLocation";
import Footer from "../../Footer/Footer";


import './Five.css';
import Icon from "../../../img/changeCamp/icons/03-change.png";

class Five extends React.Component {
    render() {
        return (
            <div>
                <Header />
                <div>
                    <div className="five_page">
                        <div className="chang_block">
                            <Container >
                                <Row>
                                    <Col xs="12" sm="8" lg="6" md={{ offset: 6 }}>
                                        <div className="change_text bg_fiveText">
                                            <p className="tittle"><img src={Icon} alt="зміна Five" /> 3 ЗМІНА</p>
                                            <p className="tittle">«Діссендіум»</p>
                                            <p>15.07-26.07.2022</p>
                                            {/* <ul>
                                                <li><span>Відсвяткуємо День народження кожного учасника зміни</span></li>
                                                <li><span>Проведемо «Холлі фестиваль»</span></li>
                                                <li><span>Відзначимо різноманітні свята</span></li>
                                                <li><span>Визначимо учасників, які відпочивали у таборі найбільшу кількість разів</span></li>
                                                <li><span>Грандіозно відсвяткуємо День народження табору</span></li>
                                            </ul> */}
                                            <div className="price">18 000 ₴</div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        <div className="change_text bg_fiveText">
                                            <div className="t-indent">Школа магії Хоґвартс оголошує додатковий набір студентів!
                                            </div>
                                            <div className="t-indent">Всі наші учні будуть розподілені по факультетах шляхом сортування розумного старого
                                                капелюха. На школу Хоґвартс ми подивимося очима персонажів книжок, навчимося
                                                чаклунству в школі юних магів та захисту від темних мистецтв. Пограємо в квідич і
                                                розкриємо таємні задуми темних сил.
                                            </div>
                                            <div className="t-indent">Нас чекає різноманітний спектр майстерень, де ми зможемо не лише прокачати творчі
                                                навички, а й навчимося керувати часом та створимо свій чарівний світ.
                                            </div>
                                            <div className="t-indent">Стати учнем найвідомішої у всьому світі Школи чарівників – мрія кожної дитини, та й не
                                                лише дитини. Діссендіум - таємний прохід для тебе вже відкритий ...
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                    <CampList />
                    <CampTermsBlock />
                    <DayTime />
                    <ChangeBlock />
                    <TeamBlock />
                    <CampLocation />
                </div>
                <Footer />
            </div >
        );
    }
}

export default Five;
