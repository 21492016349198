import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Header from "../../Header/Header";
import CampList from "../../Blocks/CampList/CampList";
import CampTermsBlock from "../../Blocks/CampTermsBlock/CampTermsBlock";
import DayTime from "../../Blocks/DayTime/DayTime";
import ChangeBlock from "../../Blocks/ChangeBlock/ChangeBlock";
import TeamBlock from "../../Blocks/TeamBlock/TeamBlock";
import CampLocation from "../../Blocks/CampLocation/CampLocation";
import Footer from "../../Footer/Footer";


import './Food.css';
import Icon from "../../../img/changeCamp/icons/02-change.png";

class Food extends React.Component {
    render() {
        return (
            <div>
                <Header />
                <div>
                    <div className="food_page">
                        <div className="chang_block">
                            <Container >
                                <Row>
                                    <Col xs="12" sm="8" lg="6" md={{ offset: 6 }}>
                                        <div className="change_text bg_foodText">
                                            <p className="tittle"><img src={Icon} alt="зміна Food" /> 2 ЗМІНА</p>
                                            <p className="tittle">«По той бік екрану»</p>
                                            <p>30.06-11.07.2022</p>
                                            {/* <ul>
                                                <li><span>Практика приготування різних страв</span></li>
                                                <li><span>«Коктейль паті» біля басейну</span></li>
                                                <li><span>Кулінарний рекорд з приготування найбільшої страви</span></li>
                                                <li><span>Зустріч з фахівцем здорового харчування</span></li>
                                                <li><span>«Grill party»</span></li>
                                            </ul> */}
                                            <div className="price">18 000 ₴</div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        <div className="change_text bg_foodText">
                                            <div className="t-indent">Шоу починається! Але бути глядачем занадто сумно! Тому ми заглянемо по той бік
                                                екрану і станемо учасниками цікавих шоу, різноманітних фільмів, а можливо і
                                                мультфільмів!
                                            </div>
                                            <div className="t-indent">Му зустрінемося з ведучими радіо та телебачення, спробуємо себе у ролі ведучих, а
                                                можливо і акторів кіно.
                                            </div>
                                            <div className="t-indent">Ми впевнені, що кожному з нас хотілося стати учасником якого небудь шоу. Ти про яке
                                                мріяв ? Маска, Голос країни чи Форт Боярд?
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                    <CampList />
                    <CampTermsBlock />
                    <DayTime />
                    <ChangeBlock />
                    <TeamBlock />
                    <CampLocation />
                </div>
                <Footer />
            </div>
        );
    }
}

export default Food;
